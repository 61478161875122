import React from 'react';
import { GiRobotAntennas } from "react-icons/gi";
import { FaRobot } from "react-icons/fa6";
import { GrProjects } from "react-icons/gr";
import { MdDesignServices } from "react-icons/md";

import { GoTools } from "react-icons/go";

import { motion } from "framer-motion"

import { fadeIn } from '../variants';
const Services = () => {
  return <section className='section ' id='services'>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center 
      lg:gap-x-20 lg:gap-y-0 h-screen'>

        {/* Father */}
        <div className='flex flex-col'>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex flex-col justify-center lg:justify-start items-center lg:items-start'
          >
            <h2 className='h2 text-accent'>Services</h2>
            <h3 className='h3 mb-4'>Uniqx Services</h3>
           
          </motion.div>



          <div className='flex flex-row items-center  lg:gap-x-20 lg:gap-y-0  '>

            <motion.div
            variants={fadeIn("right", 0.7)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            
            
            className='flex flex-col items-center gap-y-10  '>
          {/* Child 1 */}
          <div className="flex flex-row-reverse items-center lg:gap-x-5  gap-x-8">
            {/* Text */}
            <div>
              <h3  className='text-accent'>Projects</h3>
              <p>We have a Prefect Projects !</p>
            </div>
            {/* Icon */}
            <div>
              <GrProjects className="text-accent " />

            </div>
            {/* End Child 1 */}
          </div>





          {/* Child 2 */}
          <div className="flex flex-row-reverse items-center lg:gap-x-5 gap-x-8">
            {/* Text */}
            <div>
              <h3  className='text-accent'>Bot Maker</h3>
              <p>Best bot for buy bots 24/7</p>
            </div>
            {/* Icon */}
            <div>
              <FaRobot className=" text-accent " />

            </div>
            {/* End Child 2 */}
          </div>
{/* s */}
          </motion.div>

          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            
             className='flex flex-col items-center gap-y-10 '>
          {/* Child 3 */}
          <div className="flex flex-row-reverse items-center lg:gap-x-5 gap-x-5 px-5">
            {/* Text */}
            <div>
              <h3 className='text-accent'>Designs</h3>
              <p>Best Designs with high quality !</p>
            </div>
            {/* Icon */}
            <div>
              <MdDesignServices className=" text-accent " />

            </div>
            {/* End Child 3*/}
          </div>







          {/* Child 4 */}
          <div className="flex  flex-row-reverse items-center justify-center pr-10 gap-x-5">
            {/* Text */}
            <div>
              <h3 className='text-accent'>Tools</h3>
              <p>Best and Unique tools</p>
            </div>
            {/* Icon */}
            <div>
              <GoTools className=" text-accent " />

            </div>
            {/* End Child 4*/}
            </div>
          </motion.div>
          </div>
          {/* End Father */}
        </div>



        {/* image */}
        <motion.div
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className='hidden lg:flex lg:flex-1 lg:bg-services lg:bg-contain lg:mix-blend-lighten  lg:h-[340px] lg:bg-no-repeat lg:bg-top '>

        </motion.div>
      </div>
    </div>
  </section>;
};

export default Services;
