import React from 'react';

import { BiHomeAlt } from "react-icons/bi"


import {  BsBriefcase } from "react-icons/bs"

import { Link } from "react-scroll"

import { VscBracketDot } from "react-icons/vsc";
import { AiOutlineTeam } from "react-icons/ai";


const Nav = () => {
  return <nav className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
    <div className='container mx-auto'>
      {/* nav inner */}

      <div className='w-full bg-black/20 h-[96px] backdrop-blur-2x1 rounded-full max-w-[460px] 
      mx-auto px-5 flex justify-between items-center text-2x1 text-white/50'>
        <Link 
        activeClass="active"
          smooth={true}
          spy={true}
          to="home" 
          offset={-200}
          className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">

          <BiHomeAlt />
        </Link>

        <Link activeClass="active"
          smooth={true}
          spy={true}

          to="about" 
          className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">

          <VscBracketDot  />
        </Link>

        <Link activeClass="active"
          smooth={true}
          spy={true}
          to="services"
          className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center"
        >
          <BsBriefcase />
        </Link>


        <Link activeClass="active"
          smooth={true}
          spy={true}
          to="developers" 
          className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
          <AiOutlineTeam />
        </Link>

      </div>
    </div>
  </nav>;
};

export default Nav;
