import React, { useEffect } from "react";
import CardPlan from "../CardPlan";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import CardTop from "../CardTop";

import Img from "../../assets/uniqx/logo.png";

function TopCoins(props) {
  let { data } = props;

  return (
    <main className="main-container">
      <div className="main-title">
        <motion.h3
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="h3 text-center w-full text-accent"
        >
          Coins Top
        </motion.h3>
      </div>

      <div>
        {data.map((dataa, i) => {
          <div key={i}>
            <CardTop
              username={dataa.username}
              avatar={dataa.avatar ? dataa.avatar : Img}
              coins={dataa.coins}
              id={i + 1}
            />
          </div>;
        })}
      </div>
    </main>
  );
}

export default TopCoins;
