import React from "react";
import { FaArrowLeft, FaHome } from "react-icons/fa";
import { IoStorefront } from "react-icons/io5";
import { LiaRobotSolid } from "react-icons/lia";
// import { GoTools } from "react-icons/go";
// import { FaCoins } from "react-icons/fa";
// import { FiStar } from "react-icons/fi";
// import { FaServer } from "react-icons/fa";
import { Link } from "react-router-dom";
import Brand from "../../assets/loo.png";
// import { SiDocsdotrs } from "react-icons/si";
// import { IoSettings } from "react-icons/io5";
// import { FaMedal } from "react-icons/fa";

import { IoLogOutOutline } from "react-icons/io5";
function Sidebar({ openSidebarToggle, OpenSidebar }) {
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive z-999" : "z-999"}
    >
      <div className="sidebar-title">
        <div className="sidebar-brand">
          <Link to="/">
            <img className="w-[80px]" src={Brand} alt="" />
          </Link>
        </div>
        <span className="icon close_icon" onClick={OpenSidebar}>
          {" "}
          <FaArrowLeft />{" "}
        </span>
      </div>

      <ul className="sidebar-list">
        <li className="sidebar-list-item">
          <Link to="/dashboard" className="sidebar-link">
            <FaHome className="icon" /> <p>Dashboard</p>
          </Link>
        </li>
        <li className="sidebar-list-item">
          <Link to="/marketplace" className="sidebar-link">
            <IoStorefront className="icon" /> <p>MarketPlace</p>
          </Link>
        </li>
        {/* <li className="sidebar-list-item">
          <Link to="/topcoins" className="sidebar-link">
            <FaMedal className="icon" /> <p>Righest 10 Clients</p>
          </Link>
        </li> */}
        {/* <li className="sidebar-list-item">
          <Link to="/subscription" className="sidebar-link">
            <FiStar className="icon" /> <p>Subscription</p>
          </Link>
        </li>
        <li className="sidebar-list-item mt-9 mb-9">
          <Link to="/buycoins" className="sidebar-link ">
            <FaCoins className="icon" /> <p>Buy Coins</p>
          </Link>
        </li> */}
        <li className="sidebar-list-item">
          <Link to="/inventory/bots" className="sidebar-link">
            <LiaRobotSolid className="icon" /> <p>Bots</p>
          </Link>
        </li>
        {/* <li className="sidebar-list-item">
          <Link to="/inventory/projects" className="sidebar-link">
            <GoTools className="icon" /> <p>Projects</p>
          </Link>
        </li>
        <li className="sidebar-list-item">
          <Link to="/inventory/hosting" className="sidebar-link">
            <FaServer className="icon" /> <p>Hosting</p>
          </Link>
        </li>

        <li className="sidebar-list-item mt-9 ">
          <Link to="/docs" className="sidebar-link ">
            <SiDocsdotrs className="icon" /> <p>API</p>
          </Link>
        </li> */}

        {/* <li className="sidebar-list-item">
          <Link to="/settings" className="sidebar-link">
            <IoSettings className="icon" /> <p>Settings</p>
          </Link>
        </li> */}

        <li className="sidebar-list-item   icon-logout1">
          <button
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
            className="sidebar-link "
          >
            <IoLogOutOutline className="icon " /> <p >Logout</p>
          </button>
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;
