"use client";
import React, { useRef, useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "..";
function EditProfileC({ id, setBot }) {
  const data = [
    { name: "avatar", placeholder: "Type the url of avatar", type: "avatar" },
    {
      name: "username",
      placeholder: "Type the new username of your bot",
      type: "username",
    },
    { name: "banner", placeholder: "Type the url of banner", type: "banner" },
    {
      name: "prefix",
      placeholder: "Type the prefix what you want to change",
      type: "prefix",
    },
    {
      name: "guildid",
      placeholder: "Type the guild id which the bot works in",
      type: "guildid",
    },
  ];

  const [dataArray, setDataArray] = useState();
  let btn = useRef();
  const handleChange = (e, type) => {
    setDataArray({ ...dataArray, [type]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDataArray({});
    for (let i = 0; i < data.length; i++) {
      e.target[i].value = null;
    }
    btn.current.disabled = true;
    btn.current.style.cursor = "not-allowed";
    if (dataArray == null || !dataArray || dataArray == {}) {
      btn.current.style.cursor = "pointer";
      btn.current.disabled = false;
      toast.error("Please fill the form to save it", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom,
      });
    } else {
      api
        .put(`api/bots/${id}`, dataArray)
        .then(() => {
          btn.current.disabled = false;
          btn.current.style.cursor = "pointer";
          setBot(dataArray);
          toast.success("Data was edited successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Zoom,
          });
        })
        .catch((error) => {
          btn.current.disabled = false;
          btn.current.style.cursor = "pointer";
          toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Zoom,
          });
        });
    }
  };
  return (
    <>
      {data.length > 0 && (
        <div className="w-full">
          <div className="flex flex-col gap-x-5 items-center justify-center p-5 rounded bg-[#111524] card-manage w-full">
            <h3 className="h3 text-center">Edit Bot's Profile</h3>
            <form
              action="/bots/edit"
              onSubmit={handleSubmit}
              className="flex flex-col w-full items-center  gap-y-5"
            >
              {data.map((m, i) => (
                <div className="flex flex-col w-full" key={i}>
                  <label htmlFor={m.type} className="text-gray-600 ">
                    Please Type the {m.name}
                  </label>
                  <input
                    className="input-balance"
                    name={m.type}
                    placeholder={m.placeholder}
                    type="text"
                    onChange={(e) => handleChange(e, m.type)}
                  />
                </div>
              ))}
              <button
                type="submit"
                className="submit-balance"
                value="Save Changes"
                ref={btn}
                id="edit-profile-form-button"
              >
                Save Changes
              </button>
            </form>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}
export default EditProfileC;
