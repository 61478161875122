import { BrowserRouter, Route, Routes } from "react-router-dom";
// components
// import Subscriptions from "./pages/Subscription";
// import BuyCoins from "./pages/Buycoins";
// import ProjectsInv from "./pages/Projects";
// import HostingP from "./pages/Hosting";
import Home from "./pages/home";
import Prices from "./pages/prices";
import Dashboard from "./pages/Dashboard";
import Marketplace from "./pages/MarketPlace";
import BotsInv from "./pages/BotsInv";
import ManageP from "./pages/ManageBot";
import BuyBot from "./components/dashboard/BuyBot";
import { useState } from "react";
import { useEffect } from "react";
import { api } from ".";
import Loading from "./pages/Loading";
import TopPage from "./pages/Top";
const arr = [
  "dashboard",
  "marketplace",
  // "/subscription",
  "buycoins",
  "bots",
  "projects",
  "hosting",
  "docs",
  "topcoins",
  "settings",
  "inventorybots",
  "manage"
];

const App = () => {
  let paths = window.location.pathname.split("/")
  let [login, setlogin] = useState(null);
  useEffect(() => {
    api
      .get("api/user/auth")
      .then(() => setlogin(true))
      .catch(() => setlogin(false));
  }, []);
  if (login == null) return <><Loading /></>;
  else
    return (
      <>
        <BrowserRouter>
          <Routes>
            {
              
            arr.find(f=>paths.includes(f)) && login == true ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="hosting" element={<Prices />} />
                <Route exact path="dashboard" element={<Dashboard />} />
                <Route exact path="marketplace" element={<Marketplace />} />
                <Route path="inventory/bots" element={<BotsInv />} />
                <Route exact path="bots/:id/manage" element={<ManageP />} />
                <Route exact path="bots/:kind/buy" element={<BuyBot />} />
                {/* <Route exact path="topcoins" element={<TopPage />} /> */}

                {/* <Route exact path="/subscription" element={<Subscriptions />} /> */}
                {/* <Route exact path="/buycoins" element={<BuyCoins />} /> */}
                {/* <Route
                  exact
                  path="/inventory/projects"
                  element={<ProjectsInv />}
                /> */}
                {/* <Route exact path="/inventory/hosting" element={<HostingP />} /> */}
                <Route path="*" element={<Home />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="hosting" element={<Prices />} />
                <Route path="*" element={<Home />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </>
    );
};

export default App;
