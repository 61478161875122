"use client";
import React from "react";
import Banner from "../assets/uniqx/dd.png";
import Avatar from "../assets/loo.png";
function BotProfileC(props) {
  return (
    <>
      <div className="flex flex-col items-center card-manage">
        <div>
          <img
            src={props.banner !== undefined ? props.banner : Banner}
            className="w-[700px] bg-cover bg-no-repeat no-reapeat"
            alt=""
          />
        </div>
        <div className="w-full h-full flex flex-row gap-x-5  items-center ">
          <div>
            <img
              src={props.avatar !== undefined ? props.avatar : Avatar}
              className="w-[100px] rounded-full  z-50"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-gray-300 ">
              {props.username ? props.username : "Uniqx Bot"}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
export default BotProfileC;
