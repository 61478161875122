"use client";
import React from "react";
function ConfigCard(props) {
  return (
    <>
      {props.data.length > 0 && (
        <div className="w-full">
          <div className="flex flex-col gap-x-5 items-center justify-center p-5 rounded bg-[#111524] card-manage w-full">
            <h3 className="h3 text-center">Config</h3>
            <div className="flex flex-col w-full items-center  gap-y-5">
              {props.data.map((m) => {
                return (
                  <>
                    <div className="flex flex-col w-full">
                      <>
                        <label htmlFor={m.type} className="text-gray-600 f">
                          {m.name}
                        </label>
                        <input
                          className="input-balance config-input"
                          name={m.name}
                          value={m.value ? m.value : "No Data Was Provided"}
                          placeholder={m.value}
                          type="text"
                          disabled
                        />
                      </>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ConfigCard;
