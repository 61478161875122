import React, { useEffect, useState } from 'react';
import Sidebar from '../components/dashboard/Side';
import Headerr from "../components/dashboard/Headerr";
import Bots from '../components/dashboard/Bots';
import { api } from '..';
import Loading from './Loading';

const BotsInv = () => {
  let [user,setuser] = useState(null)
  useEffect(()=>{
    api.get("api/user").then(({data})=>setuser(data))
  },[])
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  if(!user) return <Loading />
  return (
    <div className='grid-container '>
      <Headerr OpenSidebar={OpenSidebar} user={user}/> 
       <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <Bots />
    
    </div>
  );
};

export default BotsInv;
