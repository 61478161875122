import React, { useEffect, useState } from "react";
import { FaCoins, FaRobot, FaTools } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { fadeIn } from "../../variants";
import { motion } from "framer-motion";

function Home(props) {
  let { user } = props;
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <main className="main-container">
      <div className="main-title">
        <h3 className="h3">Welcome Back !</h3>
      </div>

      <div className="main-cards " ref={ref}>
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="grid-card "
        >
          <div className="maintitlecard">
            <h4>Coins</h4>
          </div>
          <div className="maincontentcard">
            <div>
              <span className="h3 text-accent contantd ">
                {" "}
                {inView ? (
                  <CountUp start={0} end={user.coins} duration={6} />
                ) : null}
              </span>
            </div>
            <div>
              <span>Coins</span>
            </div>
          </div>
          <div className="mainfootercard">
            <FaCoins className="text-accent" />
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("right", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="grid-card "
        >
          <div className="maintitlecard">
            <h4>Bots</h4>
          </div>
          <div className="maincontentcard">
            <div>
              <span className="h3 text-accent contantd">
                {inView ? (
                  <CountUp start={0} end={user.bots} duration={6} />
                ) : null}
              </span>
            </div>
            <div>
              <span>Bots</span>
            </div>
          </div>
          <div className="mainfootercard">
            <FaRobot className="text-accent" />
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("right", 0.7)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="grid-card "
        >
          <div className="maintitlecard">
            <h4>Projects</h4>
          </div>
          <div className="maincontentcard">
            <div>
              <span className="h3 text-accent contantd">
                {inView ? (
                  <CountUp start={0} end={user.projects} duration={6} />
                ) : null}
              </span>
            </div>
            <div>
              <span>Projects</span>
            </div>
          </div>
          <div className="mainfootercard">
            <FaTools className="text-accent" />
          </div>
        </motion.div>
      </div>
    </main>
  );
}

export default Home;
