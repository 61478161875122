"use client";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import { Table } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { api } from "../..";
import Loading from "../../pages/Loading";

function Bots() {
  let [bots, setBots] = useState(null);
  useEffect(() => {
    api
      .get("api/bots")
      .then(({ data }) => setBots(data))
      .catch(() => (window.location.href = "/"));
  },[]);
  const customTheme = {
    root: {
      base: "w-full text-left text-sm text-gray-500 dark:text-gray-400",
      shadow:
        "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-primaryy drop-shadow-md dark:bg-black",
      wrapper: "relative",
    },
    body: {
      base: "group/body",
      cell: {
        base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
      },
    },
    head: {
      base: "group/head text-xs uppercase text-gray-700 dark:text-gray-400",
      cell: {
        base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700",
      },
    },
    row: {
      base: "group/row",
      hovered: "hover:bg-primaryy dark:hover:bg-primaryy",
      striped: " dark:bg-secondarry  even:dark:bg-primaryy bg-primaryy",
    },
  };
  if (bots == null) return <Loading />
  else
    return (
      <main className="main-container">
        <div className="main-title">
          <motion.h3
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="h3 text-accent text-center w-full"
          >
            Your Bots
          </motion.h3>
        </div>
        <motion.div
          variants={fadeIn("up", 0.6)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="overflow-x-auto"
        >
          <Table theme={customTheme}>
            <Table.Head>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Id</Table.HeadCell>
              <Table.HeadCell>Kind</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>
                <span className="sr-only">Manage</span>
              </Table.HeadCell>
            </Table.Head>

            {bots.map((bot, i) => (
              <Table.Body className="divide-y" key={i}>
                <Table.Row className="bg-primaryy dark:border-gray-700 ">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {bot.username}
                  </Table.Cell>
                  <Table.Cell>{bot.id}</Table.Cell>
                  <Table.Cell>{bot.kind}</Table.Cell>
                  <Table.Cell>
                    {bot.status == "online" ? (
                      <FaCircle className="text-online text-center ml-3" />
                    ) : (
                      <FaCircle className="text-offline text-center" />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/bots/${bot.id}/manage`}
                      className="font-medium text-[15px] text-accent  dark:text-accent"
                    >
                      Manage
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        </motion.div>
      </main>
    );
}
export default Bots;
