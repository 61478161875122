import React, { useState, useEffect } from "react";
import { api } from "../..";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "../../pages/Loading";
function BuyBot() {
  const Location = useNavigate();
  const { kind } = useParams();
  const [dataArray, setDataArray] = useState({});
  const [dataBot, setBot] = useState(false);
  const handleChange = (e) => {
    setDataArray({ ...dataArray, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      dataArray == null ||
      !dataArray ||
      dataArray == {} ||
      !dataArray.token ||
      !dataArray.prefix ||
      !dataArray.guild ||
      !kind
    ) {
      toast.error("Please fill the full form", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom,
      });
      setDataArray({});
    } else {
      if (dataArray.prefix.length > 5) {
        toast.error("Prefix length should between 2 to 5 caracter", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Zoom,
        });
      } else {
        setBot(true);
        api
          .post("api/bots", {
            token: dataArray.token,
            prefix: dataArray.prefix,
            guild:dataArray.guild,
            kind,
          })
          .then(({ data }) => {
            setBot(false);
            toast.success("Successfully Created !", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Zoom,
            });
            setTimeout(async () => {
              Location("/inventory/bots");
            }, 3000);
          })
          .catch((err) => {
            setBot(false);
            console.log(err);
            setTimeout(() => {
              toast.error(`${err.response.data}`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Zoom,
              });
            }, 1000);
          });
      }
    }
  };
  if (dataBot == true)
    return (
      <>
        <Loading />{" "}
      </>
    );
  return (
    <main className="main-container">
      <div className="main-title">
        <motion.h3
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="h3 w-full text-center text-accent"
        >
          Buy Bot
        </motion.h3>
      </div>
      <motion.div
        variants={fadeIn("up", 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="form-cont mx-auto container"
      >
        <div>
          <h3 className="h3">Let's Buy Bot</h3>
        </div>
        <div className="w-full flex items-center flex-col  ">
          <form className="fo-cont " onSubmit={(e) => handleSubmit(e)}>
            <input
              type="text"
              className="input-balance"
              placeholder="Type prefix"
              name="prefix"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="text"
              className="input-balance"
              placeholder="Type Token"
              name="token"
              onChange={(e) => handleChange(e)}
            />

<input
              type="text"
              className="input-balance"
              placeholder="Type Guild id wants bot to works in"
              name="guild"
              onChange={(e) => handleChange(e)}
            />
            {dataBot == false ? (
              <input type="submit" className="submit-balance" value="Submit" />
            ) : (
              <input
                type="submit"
                className="submit-balance"
                value="Submit"
                disabled
              />
            )}
          </form>
        </div>
      </motion.div>

      <ToastContainer />
    </main>
  );
}

export default BuyBot;
