import React from 'react';
import Image from "../assets/ill/welcome.svg"


import { TypeAnimation } from "react-type-animation"

import { motion } from "framer-motion"


import { fadeIn } from "../variants"


const Banner = () => {
    return <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
        <div className='container mx-auto'>
            <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>
                {/* text */}
                <div className='flex-1 text-center font-secondary lg:text-left '>
                    <motion.h1
                        variants={fadeIn("up", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className='text-[45px] font-bold leading-[0.8] lg:text-[90px]'>
                        Uniqx<span> Services</span>
                    </motion.h1>
                    <motion.div
                       variants={fadeIn("up", 0.4)}
                       initial="hidden"
                       whileInView={"show"}
                       viewport={{ once: false, amount: 0.7 }}
                     className='mb-6  font-secondary font-semibold uppercase leading-[1]'>
                        <span className=' lg:text-[45px] text-[35px]  text-white mr-4'>Manage Your bot</span>
                        <TypeAnimation sequence={[
                            "With Case",
                            2000,
                            "Fast",
                            2000

                        ]}
                            spead={50}
                            className="text-accent text-[30px] lg:text-[50px]"
                            wrapper="span"
                            repeat={Infinity}

                        />
                          </motion.div>
                        {/* <motion.p 
                           variants={fadeIn("up", 0.5)}
                           initial="hidden"
                           whileInView={"show"}
                           viewport={{ once: false, amount: 0.7 }}
                        className='mb-8 max-w-lg mx-auto lg:mx-0  text-[20px] '>Best server for All programming services
                        </motion.p> */}
                      
                        <motion.div 
                        variants={fadeIn("up", 0.6)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                   
                        className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
                            <button className='btn btn-lg'> <a href="/dashboard">Start Now</a> </button>
                            <a href='https://discord.gg/uniqx' className='text-gradient btn-link'>Discord Server</a>
                        </motion.div>
                    
                </div>



                <motion.div
                variants={fadeIn("down", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
           
                 className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
                    {/* Image */}
                    <img className='w-[500px]' src={Image} alt='asd' />
                </motion.div>

            </div>

        </div>


    </section>;
};

export default Banner;
