import React from 'react';
// components
import Header from '../components/Header';
import Nav from '../components/Nav';
import About from '../components/About';
import Services from '../components/Services';
import Developers from '../components/Developers';
import Banner from '../components/Banner';
import Footer from "../components/footer/Component"
import Sidebar from '../components/dashboard/Side';
const Home = () => {
  return (
    <div className='bg-primaryy bg-no-repeat bg-cover overflow-hidden'>
      <Header />
      <Nav />
      <Banner />
      <About />
      <Services />
      <Developers />
      <Footer />
      
    </div>
  );
};

export default Home;
