"use client";
import React, { useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "..";
function ManageCard(props) {
  const [dataArray, setDataArray] = useState();

  const handleChange = (e, type) => {
    setDataArray({ ...dataArray, [type]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDataArray({});
    for (let i = 0; i < props.data.length; i++) {
      e.target[`${i}`].value = null;
    }
    let btn = document.getElementById("submit-edit-profile-bot-btn");
    btn.style.cursor = "not-allowed";
    if (dataArray == null || !dataArray || dataArray == {}) {
      toast.error("Please fill the form to save it", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Zoom,
      });
      btn.style.cursor = "pointer";
    } else {
      api
        .put(`api/bots/${props.bot.id}/manage`, dataArray)
        .then(({ data }) => {
          props.setConfig(data);
          toast.success("Data was saved successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Zoom,
          });
          btn.style.cursor = "pointer";
        })
        .catch((e) => {
          toast.error(e.response.data, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Zoom,
          });
          btn.style.cursor = "pointer";
        });
    }
  };
  return (
    <>
      {props.data.length > 0 && (
        <div className="w-full">
          <div className="flex flex-col gap-x-5 items-center justify-center p-5 rounded bg-[#111524] card-manage w-full">
            <h3 className="h3 text-center">Edit</h3>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full items-center  gap-y-5"
            >
              {props.data.map((m) => {
                return (
                  <>
                    <div className="flex flex-col w-full">
                      <label htmlFor={m.name} className="text-gray-600 ">
                        Please Type the {m.name}
                     
                      </label>
                      <input
                        className="input-balance"
                        name={m.name}                      
                        type="text"
                        onChange={(e) => handleChange(e, m.name)}
                      />
                    </div>
                  </>
                );
              })}
              <button
                type="submit"
                className="submit-balance"
                value="Save Changes"
                id="submit-edit-profile-bot-btn"
              >
                Save Changes
              </button>
            </form>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}
export default ManageCard;
