import React, { useEffect, useState } from "react";
import CardMarket from "../CardMarket";
import { fadeIn } from "../../variants";
import { motion } from "framer-motion";
import { api } from "../..";
import Loading from "../../pages/Loading";
function MarketPlace() {
  let [bots, setbots] = useState();
  let [visibleSection, setVisibleSection] = useState(null);
  useEffect(() => {
    api.get("api/products/kinds").then(({ data }) => setbots(data));
  }, []);
  const handleClick = (e, section) => {
    setVisibleSection(section);
  };
  
  if (!bots) return <><Loading /></>;
  return (
    <main className="main-container">
      <div className="flex flex-col gap-y-5 items-center justify-center">
        <motion.h3
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="h3 text-center w-full text-[40px] text-accent"
        >
          Market
        </motion.h3>
        <motion.p
          variants={fadeIn("up", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="text-center text-[15px]"
        >
      
Marketplace Discord bots streamline transactions and foster community engagement by offering features such as listing services, transaction management, and escrow services. 
These bots enhance security through verification systems and moderation tools while providing users with a seamless platform for buying, selling, and trading within Discord servers.

        </motion.p>
        <motion.div
          variants={fadeIn("up", 0.7)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="flex flex-row gap-x-5 text-white rounded-full p-5 items-center shows"
        >
          <button
            onClick={(e) => handleClick(e, "bots")}
            className={visibleSection == "bots" && "bg-secondarry buttonmarket"}
          >
            Bots
          </button>
          {/* <button
            onClick={(e) => handleClick(e, "custom")}
            className={
              visibleSection == "custom" && "bg-secondarry buttonmarket"
            }
          >
            Build Bot
          </button>
          <button
            onClick={(e) => handleClick(e, "projects")}
            className={
              visibleSection == "projects" && "bg-secondarry buttonmarket"
            }
          >
            Projects
          </button>
          <button
            onClick={(e) => handleClick(e, "tools")}
            className={
              visibleSection == "tools" && "bg-secondarry buttonmarket"
            }
          >
            Tools
          </button>
          <button
            onClick={(e) => handleClick(e, "hosting")}
            className={
              visibleSection == "hosting" && "bg-secondarry buttonmarket"
            }
          >
            Hosting
          </button> */}
        </motion.div>
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap gap-x-10 gap-y-10 sad	">
        {visibleSection === "bots" &&
          bots.map((bot) => (
            <CardMarket
              name={bot.display_name}
              description={bot.description}
              price={bot.price}
              img={bot.image}
              kind={bot.kind}
            />
          ))}
        {/* {visibleSection === "projects" &&
          projects.map((project) => (
            <CardMarket
              name={project.name}
              description={project.description}
              price={project.prices}
              img={project.image}
              kind="projects"
            />
          ))}

        {visibleSection === "tools" &&
          tools.map((tool) => (
            <CardMarket
              name={tool.name}
              description={tool.description}
              price={tool.prices}
              img={tool.img}
              kind="tools"
            />
          ))}

        {visibleSection === "hosting" && (
          <>
            <Pricess
              type="Bronze"
              cpu="20%"
              ram="100"
              storage="200"
              withDomin={true}
              internet="10 GB"
              price="3"
            />
            <Pricess
              type="Silver"
              cpu="25%"
              ram="130"
              storage="300"
              withDomin={true}
              internet="10 GB"
              price="4"
            />
            <Pricess
              type="Gold"
              cpu="30%"
              ram="190"
              storage="400"
              withDomin={true}
              internet="10 GB"
              price="7"
            />
            <Pricess
              type="Platinum"
              cpu="50%"
              ram="300"
              storage="500"
              withDomin={true}
              internet="10 GB"
              price="10"
            />
            <Pricess
              type="Diamond"
              cpu="75%"
              ram="700"
              storage="1000"
              withDomin={true}
              internet="10 GB"
              price="15"
            />
            <Pricess
              type="Exclusive"
              cpu="100%"
              ram="1024"
              storage="1500"
              withDomin={true}
              internet="10 GB"
              price="30"
            />
          </>
        )} */}
      </div>
    </main>
  );
}

export default MarketPlace;
