"use client";
import React from 'react';
// components
import Header from '../components/Header';

import Footer from "../components/footer/Component"
import Pricess from '../components/panel';
import Privets from '../components/panelprivate';
import { motion } from "framer-motion"
import { fadeIn } from '../variants';
import {Spinner} from "flowbite-react"
import Image from "../assets/loo.png"

const Loading = () => {
    return (
        <div className='h-[100vh]  mx-auto w-full flex flex-col gap-x-2 items-center justify-center'>
         <div className='flex flex-col gap-x-2 items-center justify-center'>
            <div>
            <div className="flex flex-row-reverse lg:gap-x-2 items-center text-accent lg:text-[35px] text-[25px]">
        <motion.h2 
           variants={fadeIn("up", 0.3)}
           initial="hidden"
           whileInView={"show"}
           viewport={{ once: false, amount: 0.7 }}
        
        className='text-accent font-primary lg:text-[35px] text-[25px] '>Uniqx Services
        </motion.h2>
        <motion.img 
           variants={fadeIn("up", 0.3)}
           initial="hidden"
           whileInView={"show"}
           viewport={{ once: false, amount: 0.7 }}
        className='w-[100px] mb-2' src={Image} alt="" />
        </div>
            </div>
            <motion.div
               variants={fadeIn("down", 0.3)}
               initial="hidden"
               whileInView={"show"}
               viewport={{ once: false, amount: 0.7 }}
            className='flex flex-wrap items-center gap-2' >
            <Spinner color="success" aria-label="loader"  />
            </motion.div>
         </div>

        </div>
    );
};

export default Loading;
