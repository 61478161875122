"use client";
import React, { useRef } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "..";
function ManageAction(props) {
  let btn1 = useRef();
  let btn2 = useRef();
  function handleInvite(e) {
    e.preventDefault();
    navigator.clipboard.writeText(
      `https://discord.com/oauth2/authorize?client_id=${props.id}&permissions=8&scope=bot+applications.commands`
    );
    toast.success("Invite Copied Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Zoom,
    });
  }
  function stopHandel(e) {
    e.preventDefault();
    btn1.current.disabled = false;
    btn2.current.disabled = false;
    btn1.current.style.cursor = "not-allowed";
    btn2.current.style.cursor = "not-allowed";
    api
      .put(`/api/bots/${props.id}/stop`)
      .then(({ data }) => {
        toast.success(data, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Zoom,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 5000);
      })
      .catch(() => {
        toast.error("the error on the server", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Zoom,
        });
      });
  }
  function restartHandel() {
    btn1.current.disabled = false;
    btn2.current.disabled = false;
    btn1.current.style.cursor = "not-allowed";
    btn2.current.style.cursor = "not-allowed";
    api
      .put(`/api/bots/${props.id}/restart`)
      .then(({ data }) => {
        toast.success(data, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Zoom,
        });
        btn1.current.disabled = false;
        btn2.current.disabled = false;
        btn1.current.style.cursor = "pointer";
        btn2.current.style.cursor = "pointer";
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 60000);
      })
      .catch(() => {
        toast.error("the error on the server", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Zoom,
        });
      });
  }
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-x-5 items-center justify-center p-5 rounded bg-[#111524] card-manage w-full">
          <h3 className="h3 text-center">Actions</h3>
          <div className="flex flex-col w-full items-center  gap-y-5">
            <button
              type="submit"
              className="submit-restart"
              value="restart"
              ref={btn1}
              onClick={restartHandel}
            >
              Restart
            </button>
            <button
              type="submit"
              className="submit-stop"
              value="stop"
              ref={btn2}
              onClick={stopHandel}
            >
              Stop & Delete
            </button>

            <button
              type="submit"
              className="submit-invite"
              value="invite"
              onClick={handleInvite}
            >
              Invite Your Bot
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
export default ManageAction;
