import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from "axios"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


export const api = axios.create({
  baseURL:"https://data.uniqx.online",
  headers:{"x-auth-token-client":localStorage.getItem("x-auth-token-id")||""}
})