import React, { useEffect, useState } from "react";
import { FaDiscord } from "react-icons/fa6";
import { MdPriceChange } from "react-icons/md";
import { Link } from "react-router-dom";
import { api } from "..";
import { BsArrowRight } from "react-icons/bs";
import Brand from "../assets/loo.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import "@radix-ui/themes/styles.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
let login =
  "https://discord.com/oauth2/authorize?client_id=1213839504872316978&response_type=code&redirect_uri=https%3A%2F%2Fdata.uniqx.online%2Fapi%2Fuser%2Flogin&scope=guilds+email+identify+guilds.join";

function Header({ OpenSidebar }) {
  let [user, setuser] = useState();
  useEffect(() => {
    let [, token] = window.location.search.slice(1).split("token=");
    if (token) {
      localStorage.setItem("x-auth-token-id", token);
      window.location.href = "/";
    }
    api
      .get("/api/user")
      .then(({ data }) => setuser(data))
      .catch(() => {});
  }, []);
  return (
    <header className="header">
    
      <div className="header-left w-[150px] flex flex-row items-center gap-x-2">
       <Link to="/"><img src={Brand} alt="" className="w-[50px] " /> </Link> 
      </div>

      <div className="header-right  items-center">
        
        <div className="flex flex-row gap-x-5 justify-end items-center">
         <Link
            to="/hosting"
            className="flex flex-row gap-x-3  items-center text-center justify-center  hover:bg-secondaryy-600  mt-4 lg:mt-0 text-[15px] "
          >
            <MdPriceChange className="text-[15px] lg:text-[20px]" /> <p> Hosting </p>
          </Link>
          {!user ? (
            <Link
              to={login}
              className="btn flex flex-row gap-x-2  items-center text-center justify-center lg:h-[45px] lg:w-[120px] h-[50px] w-[100px] mt-5 lg:m-0 hover:bg-violet-600"
            >
              <FaDiscord className="lg:text-[20px] text-[20px]" /> <p className=" text-[15px] lg:text-[20px]"> Login </p>
            </Link>
          ) : (
            <Menu as="div" className="relative inline-block text-left lg:mb-5">
              <div>
                <Menu.Button className="flex flex-row gap-x-2 items-center mt-5">
                  <img
                    className="w-[50px] rounded-full"
                    src={user.avatar}
                    fallback="A"
                  />
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-primaryy shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/"
                          className={classNames(
                            active ? "bg-dark text-gray-300" : "text-gray-300",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Home
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/dashboard"
                          className={classNames(
                            active ? "bg-dark text-gray-300" : "text-gray-300",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Dashbaord
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="panel.hrhost.xyz"
                          className={classNames(
                            active ? "bg-dark text-gray-300" : "text-gray-300",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Panel
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active ? "bg-dark text-gray-300" : "text-gray-300",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={() => {
                            localStorage.clear();
                            window.location.href = "/";
                          }}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
