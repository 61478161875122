import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Brand from "../../assets/loo.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import "@radix-ui/themes/styles.css";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header({ OpenSidebar, user }) {
  return (
    <header className="header">
      <div className="menu-icon">
        <BsArrowRight className="icon w-[40px]" onClick={OpenSidebar} />
      </div>
      <div className="header-left w-[150px] flex flex-row items-center justify-center lg:justify-none gap-x-2 lg:ml-12">
        <img src={Brand} alt="" className="w-[50px] " />
        <span className="text-accent h3 pt-5">Uniqx</span>
        <span className="h3 pt-5">Services</span>
      </div>

      <div className="header-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="flex flex-row gap-x-2 items-center mt-5">
              <img
                className="w-[50px] rounded-full"
                src={user.avatar}
                fallback="A"
              />
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-primaryy shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/"
                      className={classNames(
                        active ? "bg-dark text-gray-300" : "text-gray-300",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Home
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/dashboard"
                      className={classNames(
                        active ? "bg-dark text-gray-300" : "text-gray-300",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Dashbaord
                    </Link>
                  )}
                </Menu.Item>

                <form method="POST" action="#">
                  <Menu.Item>
                  {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active ? "bg-dark text-gray-300" : "text-gray-300",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={() => {
                            localStorage.clear();
                            window.location.href = "/";
                          }}
                        >
                          Sign out
                        </button>
                      )}
                  </Menu.Item>
                </form>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}

export default Header;
