import React from 'react';

import CountUp from "react-countup"

import { useInView } from "react-intersection-observer"

import { motion } from "framer-motion"

import { fadeIn } from '../variants';


const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  return <section className='section flex-1 text-center font-secondary lg:text-left' id='about' ref={ref}>
    <div className='container mx-auto'>
      <div className='flex flex-col-reverse gap-y-10 lg:flex-row lg:items-center 
      lg:gap-x-20 lg:gap-y-0 h-screen'>

        {/* img */}
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className='flex-1 bg-about bg-contain  h-[100px]  lg:h-[340px] bg-no-repeat '>

        </motion.div>
        <motion.div
          variants={fadeIn("left", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
        
        >
          <h2 className='h2 text-accent'>About Us</h2>
          <h3 className='h3 mb-4'>Uniqx Services</h3>
          <p className='mb-6'>
          Uniqx Services is a server includes all Programming <br></br>
          Designing Services at High Quality Service and reasonable Prices
 {/* flex text-center font-secondary lg:text-left justify-center items-center gap-x-10 */}
          </p>
          <div className='flex lg:gap-x-6 lg:justify-start lg:gap-x-10 lg:mb-12 justify-center gap-x-10 items-center'>
            {/* 1 section */}
            <div>
              <div className='font-primary text-sm tracking-[2px]'>
                Clients
              </div>

              <div className='text-[30px] font-tertiary text-gradient mb-2'>
                {
                  inView ?
                    <CountUp start={0} end={20} duration={3} /> : null

                }+
              </div>

            </div>



            {/* 2 section */}

            <div>
              <div className='font-primary text-sm tracking-[2px]'>
                Orders
              </div>

              <div className='text-[30px] font-tertiary text-gradient mb-2'>
                {
                  inView ?
                    <CountUp start={0} end={20} duration={3} /> : null

                }+
              </div>

            </div>

            {/* 3 section */}

            <div>
              <div className='font-primary text-sm tracking-[2px]'>
                Feedbacks
              </div>
              <div className='text-[30px] font-tertiary text-gradient mb-2'>
                {
                  inView ?
                    <CountUp start={0} end={20} duration={3} /> : null

                }+
              </div>

            </div>

            {/* endsection */}



          </div>
        </motion.div>


      </div>
    </div>
  </section>;
};

export default About;
