import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import ManageCard from "../ManageInputs";
import ConfigCard from "../ConfigBot";
import BotProfileC from "../BotProfile";
import EditProfileC from "../EditProfileBot";
import "@radix-ui/themes/styles.css";
import ManageAction from "../ManageActions";
import { api } from "../..";
import { useParams } from "react-router-dom";
import Loading from "../../pages/Loading";

function ManageComp() {
  let { id } = useParams();
  let [bot, setBot] = useState(null);
  let [config, setConfig] = useState(null);
  const [visibleSection, setVisibleSection] = useState(null);
  useEffect(() => {
    api.get(`api/bots/${id}`).then(({ data }) => setBot(data));
    api.get(`api/bots/${id}/manage`).then(({ data }) => setConfig(data));
  }, []);
  const handleClick = (e, section) => {
    setVisibleSection(section);
  };

  if (bot == null || config == null) return <Loading />;
  var dataConfig = Object.entries(config).map(([name, value]) => {
    return { name, value: Array.isArray(value) ? value.join(" ") : value };
  });
  var data = Object.entries(config).map(([name, value]) => {
    return {
      name,
      placeholder: Array.isArray(value) ? value.join(" ") : value,
    };
  })
  return (
    <main className="main-container">
      <div className="main-title">
        <motion.h3
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="h3 text-center w-full text-accent"
        >
          Manage
        </motion.h3>
      </div>

      <div className="flex flex-col gap-y-5 items-center">
        <BotProfileC
          avatar={bot?.avatar}
          username={bot?.username}
          banner={bot?.banner}
        />
        <motion.div
          variants={fadeIn("up", 0.7)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="flex flex-row gap-x-5 text-white rounded-full p-5 items-center shows"
        >
          <button
            onClick={(e) => handleClick(e, "editprofile")}
            className={
              visibleSection == "editprofile" && "bg-secondarry buttonmarket"
            }
          >
            Edit Profile
          </button>
          <button
            onClick={(e) => handleClick(e, "config")}
            className={
              visibleSection == "config" && "bg-secondarry buttonmarket"
            }
          >
            Show Config
          </button>
          <button
            onClick={(e) => handleClick(e, "editconfig")}
            className={
              visibleSection == "editconfig" && "bg-secondarry buttonmarket"
            }
          >
            Edit Config
          </button>
          <button
            onClick={(e) => handleClick(e, "actions")}
            className={
              visibleSection == "actions" && "bg-secondarry buttonmarket"
            }
          >
            Actions
          </button>
        </motion.div>

        {visibleSection === "editprofile" && (
          <EditProfileC id={id} setBot={setBot} />
        )}

        {visibleSection === "config" && <ConfigCard data={dataConfig} />}

        {visibleSection === "editconfig" && (
          <ManageCard data={data} bot={bot} setConfig={setConfig} />
        )}

        {visibleSection === "actions" && <ManageAction id={id} />}
      </div>
    </main>
  );
}

export default ManageComp;
