import React from 'react';
// components
import Header from '../components/Header';

import Footer from "../components/footer/Component"
import Pricess from '../components/panel';
import Privets from '../components/panelprivate';
import { motion } from "framer-motion"
import { fadeIn } from '../variants';
const Prices = () => {
    return (
        <div className='h-[100vh]  mx-auto '>
            <Header />
            <div className='min-h-[85vh] lg:min-h-[78vh]   flex flex-col  items-center justify-center gap-x-10'>
                <motion.h2
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='h2 text-accent mt-20'>Prices</motion.h2>
                <motion.h3
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='h3 mt-12'>Bots Hosting</motion.h3>
                <div
                    variants={fadeIn("up", 0.5)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='flex flex-col gap-y-5 gap-x-10 items-center justify-center lg:flex-row flex-wrap w-[800px]'>

                    {/* Small */}
                    <Pricess type="Bronze" cpu="20%" ram="100" storage="200" withDomin={true} internet="10 GB" price="3" />
                    <Pricess type="Silver" cpu="25%" ram="130" storage="300" withDomin={true} internet="10 GB" price="4" />
                    <Pricess type="Gold" cpu="30%" ram="190" storage="400" withDomin={true} internet="10 GB" price="7" />

                    {/* Big */}
                    <Pricess type="Platinum" cpu="50%" ram="300" storage="500" withDomin={true} internet="10 GB" price="10" />
                    <Pricess type="Diamond" cpu="75%" ram="700" storage="1000" withDomin={true} internet="10 GB" price="15" />
                    <Pricess type="Exclusive" cpu="100%" ram="1024" storage="1500" withDomin={true} internet="10 GB" price="30" />



                </div>



                <motion.h3
                    variants={fadeIn("right", 0.6)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className='h3 mt-12'>Private Hosts</motion.h3>
                <div



                    className='flex flex-col gap-y-5 gap-x-10 items-center justify-center lg:flex-row flex-wrap w-[800px]'>

                    <Privets type="Bronze" cpu="3" ram="4" withDomin={true} internet="10 GB" price="15" NVme="35GB" />
                    <Privets type="Silver" cpu="4" ram="6" withDomin={true} internet="10 GB" price="18" NVme="40GB" />

                    <Privets type="Gold" cpu="4" ram="8" withDomin={true} internet="10 GB" price="20" NVme="50GB" />
                    <Privets type="Diamond" cpu="6" ram="12" withDomin={true} internet="10 GB" price="25" NVme="60GB" />


                </div>
            </div>
            <Footer className="bottom-2" />

        </div>
    );
};

export default Prices;
