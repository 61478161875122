import React, { useEffect, useState } from 'react';
import Sidebar from '../components/dashboard/Side';
import Headerr from "../components/dashboard/Headerr";
import ManageComp from '../components/dashboard/managebot';
import { api } from '..';
import Loading from './Loading';

const ManageP = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  const [user,setUser] = useState(null)
  useEffect(()=>{
    api.get("/api/user")
    .then(({data})=>setUser(data))
  },[])
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  if(user == null) return <Loading />
  return (
    <div className='grid-container '>
      <Headerr OpenSidebar={OpenSidebar} user={user}/> 
       <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
  
      <ManageComp user={user} />
    
    </div>
  );
};

export default ManageP;
