
"use client";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CiLogin } from "react-icons/ci";
import {motion} from "framer-motion"
import { fadeIn } from "../variants"
function CardMarket(props) {

   
  
    


    return (

        <motion.div
        variants={fadeIn("up", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7}}
        className="card-market flex rounded flex-col items-center justify-center p-5 rounded bg-third">
            <div>
                <img src={props.img} alt={props.name} className='rounded-full w-[250px]' />
            </div>

            <div className="flex flex-col gap-x-5 w-full ">
                <div>
                    <h3 className="h3 text-center">{props.name}</h3>
                </div>

                <div className='flex flex-row justify-between fit-content'>
                    <div className='flex flex-row items-center'>
                        <p>{props.price > 10 ? `${props.price} Coin` : `${props.price} Coins`}</p>
                    </div>
                    <div>
    
                        <Link to={`/bots/${props.kind.toLowerCase()}/buy`}  className='button-buy'> <span > Buy </span> <CiLogin  /></Link>
                          </div>
                </div>
            </div>

        </motion.div>
    );
}
export default CardMarket;