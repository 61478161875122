import "./style.css"
import Image from "../../assets/loo.png"
import { motion } from "framer-motion"
import { fadeIn } from "../../variants"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <motion.div
    variants={fadeIn("up", 0.3)}
    initial="hidden"
    whileInView={"show"}
    viewport={{ once: false, amount: 0.7 }}
    className="footer h-[290px]">

 
    <div
   
    className="footer-high-layer">
        <div className="footer-high-content">
        <h2>Uniqx Services</h2>
        <img id="footer-img" src={Image} alt="" />
        </div>
        <div className="dev-icons" id="iconaty">
        <div className="dev-icon-i bg-third">
                <i class="fa-brands fa-facebook-f"></i>
                </div>

                    <div className="dev-icon-i bg-third">
                   <Link to="https://github.com/AmrMohamed2007"><i class="fa-brands fa-github"></i></Link>
                      </div>

                    <div className="dev-icon-i bg-third">
                    <i class="fa-brands fa-instagram"></i>
                      </div>

                    <div className="dev-icon-i bg-third">
                    <i class="fa-brands fa-x-twitter"></i>
                    </div>

        </div>
    </div>

    <div className="hrarr"></div>

    <div className="footer-under-layer">
      <div className="footer-ais">
        <a href="///">Privacy Policy</a>
        <a href="///">Terms Of Services</a>

      </div>
    </div>
    </motion.div>
  )
}

export default Footer