import React from 'react';
import Amr from "../assets/amr.png"
import Warsh from "../assets/warsh.png"
import Tarek from "../assets/tarek.png"
import { fadeIn } from "../variants"
import { motion } from "framer-motion"

import DevCard from './small/dev';
const Header = () => {
  return <section  id='developers'>
  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
      <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <motion.h2 
          
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-accent">Our Team</motion.h2>
       
      </div> 
      <div className="grid gap-10 mb-6 lg:mb-16 md:grid-cols-2">
      <DevCard name="Amr Mohamed" job="FullStack Developer" description="I'm Amr , 17 years old , Discord Bot Developer & FullStack Developer (MERN STACK , Web driver , Web Scraping ) Let's Build Site With Me" img={Amr} facebook="https://www.facebook.com/profile.php?id=100063469459407" github="https://github.com/AmrMohamed2007"  />
      <DevCard name="Tarek Ali" job="FullStack Developer" description="Tarek ali experience for more than two years in (backend,frontend,discord&telegram bots,web scraping and web driver) devloper" img={Tarek} facebook="https://www.facebook.com/profile.php?id=100035085232211" github="https://github.com/6tarek"  />
      <DevCard name="Warsh" job="Graphic & Ui Designer" description="Ali Warshan AKA Warsh egypiton Graphic / Uİ Designer & Frontend Developer with 2 years of experience" img={Warsh} facebook="https://www.facebook.com/profile.php?id=100075041976973&mibextid=ZbWKwL" />




      </div>
  </div>
</section>
};

export default Header;
