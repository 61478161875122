import React, { useEffect, useState } from "react";
import Sidebar from "../components/dashboard/Side";
import Headerr from "../components/dashboard/Headerr";
import Home from "../components/dashboard/Home";
import { api } from "..";
import Loading from "./Loading";

const Dashboard = () => {
  let [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  let [user, setuser] = useState(null);
  useEffect(() => {
    api.get("api/user").then(({ data }) => setuser(data));
  }, []);
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  if (user == null) return <Loading />;
  return (
    <div className="grid-container ">
      <Headerr OpenSidebar={OpenSidebar} user={user} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar} user={user}
      />

      <Home user={user}/>
    </div>
  );
};

export default Dashboard;
